import http from "../base/httpcomponent";

class MenuItemDataService {
    getAll() {
        return http.get("/menuitem?per_page=100");
    }

    getAllByCategoryId(id) {
        return http.get(`/menuitem/?menucategory=${id}&per_page=100`);
    }

    get(id) {
        return http.get(`/menuitem/${id}`);
    }
}

export default new MenuItemDataService();