import React from "react";
import "./home.css";
import fb_NY from "../pictures/fb_NY.png";
import tripadvisor_white from "../pictures/tripadvisor_white.png";
import skull0 from "../pictures/cutlery.png";
import skull1 from "../pictures/cutlery.png";
import skull2 from "../pictures/cutlery.png";
import skull3 from "../pictures/cutlery.png";
import skull4 from "../pictures/cutlery.png";
import frontdoor from "../pictures/frontdoor.png";
import ReservasEn from "../../reservas/reservas_en";
import ReservasEs from "../../reservas/reservas_es";
import GalleryItemDataService from "../../services/galleryitem.service"
import MediaDataService from "../../services/mediaitem.service"
import MainMenuCategoryDataService from "../../services/mainmenucategory.service"
import MenuItemDataService from "../../services/menuitem.service"
import MenuCategoryDataService from "../../services/menucategory.service";
import PageSectionDataService from "../../services/pagesection.service";
import MapSection from './map';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';

const location = {
    address: 'Restaurant X, Calle Moncayo 2, Fuengirola',
    lat: 36.5380611, 
    lng: -4.6219769
  }

export class Home extends React.Component {
    constructor(props) {
        super(props);
    
        this.switchMenu = this.switchMenu.bind(this);
        this.getAboutUs = this.getAboutUs.bind(this);     
        this.getMenuChoice = this.getMenuChoice.bind(this);  
        this.getContactUs = this.getContactUs.bind(this); 
        this.renderCartRow = this.renderCartRow.bind(this); 
        this.workWithMe = this.workWithMe.bind(this);
        this.retrieveCartData = this.retrieveCartData.bind(this);
        this.populateCart = this.populateCart.bind(this);  
        this.updateTotalAmount = this.updateTotalAmount.bind(this);
        this.updateTotalPrice = this.updateTotalPrice.bind(this);  
        this.renderRawCartRow = this.renderRawCartRow.bind(this);    
        this.renderGalleryItems = this.renderGalleryItems.bind(this);   
        this.retrieveMediaItem = this.retrieveMediaItem.bind(this);    
    
        this.state = {
                language: this.props.language,
                menuActive: 0,
                menuContent: "",
                menuContents: "",
                menuContentsGlutenFree: "",
                menuContentsVegan: "",
                menuContentsJson: "",
                pageSections: "",
                productsRaw: [],
                carritoClass: "carrito",
                galleryItems: [],
                mediaItems: [],
            };
        };

        updateTotalPrice (totalPrice) {
            this.setState({totalPrice: this.state.totalPrice+totalPrice});
        }

        useEffect(){
            this.switchMenu(0);
        }

        renderGalleryItems(galleryItems, mediaItems) { 
            
            return galleryItems.data.map((currentRow) => {                
                return <div>{this.retrieveMediaItem(currentRow.featured_media, mediaItems)}</div>
              });;
              
        };

        retrieveMediaItem(currentMediaId, mediaItems) {  

            return <div className="divGallery"><img className="galleryImg" src={mediaItems.data.find((element) => {
                return element.id === currentMediaId;
              }).media_details.sizes.medium.source_url}/></div>;

        };

        async retrieveCartData() {   
            return "";
        };

        async renderCartRow(someData,currentLanguage) { 
              return this.props.cartData.filter(num => num.amount > 0).map((currentRow) => {
                return this.workWithMe(currentRow,someData,currentLanguage);
              });
        }

        async renderRawCartRow(someData,currentLanguage) { 
            return this.props.cartData.filter(num => num.amount > 0).map((currentRow) => {
              return this.workWithMeRaw(currentRow,someData,currentLanguage);
            });
        }    

        workWithMeRaw (currentRow, productDataArray, currentLanguage) {

          if (!productDataArray) { return false; }
      
          const productData = productDataArray.find((element) => {
            return element.item.id === currentRow.id;
          })

          if(!productData) { return null}
      
          let finalPrizeText = "";
          let finalPrize = 0;
      
            finalPrize = productData.item.price;
            if (currentLanguage==="en") {
                finalPrizeText = productData.item.title_en ? productData.item.title_en : "";
                return ({productName: productData.item.title_en, productPrice: finalPrize, productAmount: currentRow.amount, productTotal: Number(finalPrize*currentRow.amount).toFixed(2), productId: currentRow.id, productSize: finalPrizeText, productSizeCode: currentRow.size})
            } else {
                finalPrizeText = productData.item.title_es ? productData.item.title_es : "";
                return ({productName: productData.item.title_es, productPrice: finalPrize, productAmount: currentRow.amount, productTotal: Number(finalPrize*currentRow.amount).toFixed(2), productId: currentRow.id, productSize: finalPrizeText, productSizeCode: currentRow.size})
            }
        }
      
        workWithMe (currentRow, productDataArray, currentLanguage) {

          if (!productDataArray) { return false; }
      
          const productData = productDataArray.find((element) => {
            return element.item.id === currentRow.id;
          })

          if(!productData) { return null}

          this.updateTotalPrice(productData.item.price*currentRow.amount)
      
          let finalPrize = 0;
            finalPrize = productData.item.price;
      
          const rowStyle = "row"; //amount > 0 ? "row" : "hiddenRow";
      
          return (<div className={rowStyle} key={currentLanguage + "_" + productData.item.id + "_" + currentRow.size}>
            <div className="col-6 cartName">{currentLanguage==="en" ? productData.item.title_en : productData.item.title_es}</div>
            {/* <div className="col-2">{finalPrize}€</div> */}
            <div className="col-3">
                <div className="col-12">
                  <a className="special" href="#a" onClick={()=>this.removeCartItem(productData.item.id, currentRow.size)}>-</a> &nbsp;
                  <span>{currentRow.amount}</span>&nbsp;
                  <a className="special" href="#a" onClick={()=>this.addCartItem(productData.item.id, currentRow.size,1)}>+</a>
                </div>
            </div>
            <div className="col-3 right">{Number(finalPrize*currentRow.amount).toFixed(2)}€</div>
          </div>);
        }
        
        async removeCartItem(productId,size) {
            this.props.removeCartItem(productId,size);
            toast('Quitado de tu pedido!');  
            this.populateCart(this.state.menuContentsJson);
        }

        addCartItem(input,sizeValue,amount) {
            this.props.addCartItem(input,sizeValue,amount);
            toast('Añadido a tu pedido!');                
            this.populateCart(this.state.menuContentsJson);
            this.setState({carritoClass: "carritoBlink"});  
        }
                
        async populateCart(someData) {   
            await this.updateTotalAmount();
            await this.setState({totalPrice: 0});
            this.setState({products: await this.renderCartRow(someData,this.props.language)});   
            await this.setState({productsRaw: await this.renderRawCartRow(someData,this.props.language)});  
        }

        async componentDidMount() { 
            this.switchMenu(0);

            const mainMenuContents = await MainMenuCategoryDataService.getAll();
            const subCategories = await MenuCategoryDataService.getAll();
            const menuItems = await MenuItemDataService.getAll();
            const tmpGalleryitems = await GalleryItemDataService.getAll();
            const tmpMediaItems = await MediaDataService.getAll();

            let tmpMenuContentsJson = await this.retrieveMenuContentsJson(menuItems);
            let tmpMenuContentsEn = await this.retrieveMenuContents(0,mainMenuContents,subCategories,menuItems,"en");
            let tmpMenuContentsEs = await this.retrieveMenuContents(0,mainMenuContents,subCategories,menuItems,"es");
            let tmpMenuGlutenFreeContentsEn = await this.retrieveMenuContents(1,mainMenuContents,subCategories,menuItems,"en");
            let tmpMenuGlutenFreeContentsEs = await this.retrieveMenuContents(1,mainMenuContents,subCategories,menuItems,"es");
            let tmpMenuVeganContentsEn = await this.retrieveMenuContents(2,mainMenuContents,subCategories,menuItems,"en");
            let tmpMenuVeganContentsEs = await this.retrieveMenuContents(2,mainMenuContents,subCategories,menuItems,"es");
            let tmpPageSections = await this.retrievePageSections();
            let tmpGalleryContents = this.renderGalleryItems(tmpGalleryitems, tmpMediaItems);

            this.setState({
                pageSections: tmpPageSections, 
                menuContentsEn: tmpMenuContentsEn,  
                menuContentsEs: tmpMenuContentsEs,
                menuContentsGlutenFreeEn: tmpMenuGlutenFreeContentsEn, 
                menuContentsGlutenFreeEs: tmpMenuGlutenFreeContentsEs, 
                menuContentsVeganEn: tmpMenuVeganContentsEn,  
                menuContentsVeganEs: tmpMenuVeganContentsEs, 
                menuContentsJson: tmpMenuContentsJson,
                galleryContents: tmpGalleryContents
                });

            this.populateCart(tmpMenuContentsJson);
        }

        updateTotalAmount() {
            this.setState({totalAmount: this.props.cartData.filter(num => num.amount > 0).reduce((total, currentValue) => total = total + currentValue.amount,0)});    
        }

        getSkull(no) {
            switch(no) {
                case 0:
                  return skull0;
                case 1:
                    return skull1;
                case 2:
                    return skull2;
                case 3:
                    return skull3;
                case 4:
                    return skull4;
                default:
                  return skull0;
              }
        }

        async retrievePageSections() {            
            return await PageSectionDataService.getAll();
        }

        async retrieveMenuContentsJson(menuItems) {
            var menuDetailsJson = [];
            
            for (var b = 0; b < menuItems.data.length; b++) {
                   
                    var menuItem = {
                        item: {}
                    };

                    menuItem.item = {
                        "id": menuItems.data[b].id,
                        "title_en": menuItems.data[b].acf.title_english,
                        "description_en": menuItems.data[b].acf.description_english,
                        "title_es": menuItems.data[b].acf.title_espanol,
                        "description_es": menuItems.data[b].acf.description_espanol,
                        "price": menuItems.data[b].acf.price,
                        "vegan": menuItems.data[b].acf.vegan,
                        "glutenfree": menuItems.data[b].acf.glutenfree,
                        "deliverable": menuItems.data[b].acf.deliverable,
                        "order": menuItems.data[b].acf.order,
                    }

                    menuDetailsJson.push(menuItem);

            }

            return menuDetailsJson;
        }

        async retrieveMenuContents(menuType,mainMenuContents,subCategories,menuItems,currentLanguage) {
            var menuDetailsJson = [];

            for (var i = 0; i < mainMenuContents.data.length; i++) { 
                
                var mainMenuCategory = {
                    item: {},
                    subCategories: []
                };

                mainMenuCategory.item = {
                    "id": mainMenuContents.data[i].id,
                    "title_en": mainMenuContents.data[i].acf.title_english,
                    "title_es": mainMenuContents.data[i].acf.title_espanol,
                    "order": mainMenuContents.data[i].acf.order
                }

                for (var a = 0; a < subCategories.data.length; a++) { 
                    
                    if(subCategories.data[a].acf.mainmenucategory[0]===mainMenuContents.data[i].id) {
                        
                        var menuCategory = {
                            item: {},
                            menuItems: []
                        };
    
                        menuCategory.item = {
                            "id": subCategories.data[a].id,
                            "title_en": subCategories.data[a].acf.title_english,
                            "description_en": subCategories.data[a].acf.description_english,
                            "title_es": subCategories.data[a].acf.title_espanol,
                            "description_es": subCategories.data[a].acf.description_espanol,
                            "order": subCategories.data[a].acf.order
                        }

                        let menuItemSubSet = menuItems.data.filter(x=>x.acf.menucategory[0]===subCategories.data[a].id);
                        for (var b = 0; b < menuItemSubSet.length; b++) { 
                            
                            var menuItem = {
                                item: {}
                            };

                            if (menuType===0) {
                                menuItem.item = {
                                    "id": menuItemSubSet[b].id,
                                    "title_en": menuItemSubSet[b].acf.title_english,
                                    "description_en": menuItemSubSet[b].acf.description_english,
                                    "title_es": menuItemSubSet[b].acf.title_espanol,
                                    "description_es": menuItemSubSet[b].acf.description_espanol,
                                    "price": menuItemSubSet[b].acf.price,
                                    "vegan": menuItemSubSet[b].acf.vegan,
                                    "glutenfree": menuItemSubSet[b].acf.glutenfree,
                                    "deliverable": menuItemSubSet[b].acf.deliverable,
                                    "order": menuItemSubSet[b].acf.order,
                                }
                                menuCategory.menuItems.push(menuItem);
                            }
                            
                            if (menuType===1 && menuItemSubSet[b].acf.glutenfree===true) {
                                menuItem.item = {
                                    "id": menuItemSubSet[b].id,
                                    "title_en": menuItemSubSet[b].acf.title_english,
                                    "description_en": menuItemSubSet[b].acf.description_english,
                                    "title_es": menuItemSubSet[b].acf.title_espanol,
                                    "description_es": menuItemSubSet[b].acf.description_espanol,
                                    "price": menuItemSubSet[b].acf.price,
                                    "vegan": menuItemSubSet[b].acf.vegan,
                                    "glutenfree": menuItemSubSet[b].acf.glutenfree,
                                    "deliverable": menuItemSubSet[b].acf.deliverable,
                                    "order": menuItemSubSet[b].acf.order,
                                }
                                menuCategory.menuItems.push(menuItem);
                            }

                            if (menuType===2 && menuItemSubSet[b].acf.vegan===true) {
                                menuItem.item = {
                                    "id": menuItemSubSet[b].id,
                                    "title_en": menuItemSubSet[b].acf.title_english,
                                    "description_en": menuItemSubSet[b].acf.description_english,
                                    "title_es": menuItemSubSet[b].acf.title_espanol,
                                    "description_es": menuItemSubSet[b].acf.description_espanol,
                                    "price": menuItemSubSet[b].acf.price,
                                    "vegan": menuItemSubSet[b].acf.vegan,
                                    "glutenfree": menuItemSubSet[b].acf.glutenfree,
                                    "deliverable": menuItemSubSet[b].acf.deliverable,
                                    "order": menuItemSubSet[b].acf.order,
                                }
                                menuCategory.menuItems.push(menuItem);
                            }
                        }
                        mainMenuCategory.subCategories.push(menuCategory);
                    }

                }
                menuDetailsJson.push(mainMenuCategory);
            }

            return menuDetailsJson.map((mainObject, index, menuDetailsJson) => (
                <div key={"main" + mainObject.item.id}>
                    <div className="row container">
                        <h2 className="col-md-12 header1 ram" id="menu">{currentLanguage==="en" ? mainObject.item.title_en : mainObject.item.title_es }</h2>
                        <div key={mainObject.item.id + "b"} className="row">
                            {mainObject.subCategories.filter(x=>x.menuItems.length>0).sort((a, b) => a.order > b.order ? 1:-1).map((subObject, index1) => (<div className="col-md-6 columnSetting">
                                <div key={"sub" + subObject.item.id}>
                                        {subObject.item.title_en !== "" ?  
                                            <div className="row"> 
                                                <div className="col-md-12">
                                                    <span className="subheader2">{currentLanguage==="en" ? subObject.item.title_en : subObject.item.title_es}</span><br/>
                                                    <span className="text">{currentLanguage==="en" ? subObject.item.description_en : subObject.item.description_es}</span>
                                                </div>
                                                {/* <div className="col-md-6"><img className="foto" src={nachos_1}/></div> */}
                                            </div> : ""}
                                    {subObject.menuItems.sort((a, b) => a.order > b.order ? 1:-1).map((itemObject, index2) => (
                                    <div className="row" key={"item" + itemObject.item.id}> 
                                        <div className="col-9"><span className="subheader1">{currentLanguage==="en" ? itemObject.item.title_en : itemObject.item.title_es}</span> <br/>
                                        <span className="text">{currentLanguage==="en" ? itemObject.item.description_en : itemObject.item.description_es}</span></div>
                                        <div className="col-3 price">{itemObject.item.price}&nbsp;<img className="btnAdd" alt={currentLanguage==="en" ? "Add" : "Añadir"} onClick={()=>this.addCartItem(itemObject.item.id,1,1)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABRklEQVRIie3WTy4EURDH8Q9xBOJ/QuIK/tzAn0yIW+AktjaCpSMIjiI2WBBbzE5mZCxa09NJ6+oezcYvqU13dX3rva5X9fjXL2mogu8MttDCHGY/nt/jDuc4w8NPJTeGA3TQK7E3nGJyUGgLLwFg3l6wURe6h24NaGpd7FaFrg0IzW59KwqdUm97i+wZExHwSTDgIpaDvkdl0Bmx6u1lvon4djCdBQ3nwNsYKcuuhkYkPaAQvN4ANFXf8cqDFxoEfxu7rbiQoioquHbWKb/iIlXp6VV8P3Utfj5TRf2vsqD8im/qZBtUX+w8+LJB8MV3L5tsIFNlmR0Hgy1hJeh7WAYlGeLPwYARe8J4BAyr/mAsptodEN7FTlVoqg31tv1JcpkYSKPYx2sA2JEUZ+ngr9LeprEp+Wfz+q+3t76ut48VYv6reb0DHsEhvqC5OZgAAAAASUVORK5CYII="/></div>
                                        {/* <div className="col-md-1"><img className="foto" src={nachos_1}/></div> */}
                                    </div>))}
                                </div>
                            </div>))}                                            
                        </div>    
                    </div>
                    { menuDetailsJson.length - 1 === index ? "" : <div key={"mainpic" + mainObject.item.id + "c"} className="row">
                        <div className="divider">
                            <div className="col-md-12"></div>     
                            <div>
                                <img className="icon" alt="Separator" title="Separator" src={this.getSkull(index)}/>
                            </div>
                        </div>
                    </div>}
                    
                </div>));
        }
        
        async switchMenu(inValue) {
            await this.setState({menuActive: inValue});
        }
        
        getAboutUs() {
            if (this.props.language==="en") {
                return <div className="col-md-12">
                            <h2  className="header" id="nosotros">{this.state.pageSections ? this.state.pageSections.data[1].acf.title_english : ""}</h2>
                            <p className="info">{this.state.pageSections ? this.state.pageSections.data[1].acf.description_english : ""}</p>
                        </div>;
            } else{
                return <div className="col-md-12">
                            <h2  className="header" id="nosotros">{this.state.pageSections ? this.state.pageSections.data[1].acf.title_espanol : ""}</h2>
                            <p className="info">{this.state.pageSections ? this.state.pageSections.data[1].acf.description_espanol : ""}</p>
                        </div>;
            }
        }

        getMenuChoice() {
            if (this.props.language==="en") {
                return <div className="container col-md-12 menuSelector">
                <span className="special" onClick={()=>this.switchMenu(0)} id="menu" href="menu">Original Menu</span>&nbsp;|&nbsp;
                <span className="special" onClick={()=>this.switchMenu(1)} id="gluten" href="gluten">Gluten Free Menu</span>&nbsp;|&nbsp;
                <span className="special" onClick={()=>this.switchMenu(2)} id="vegano" href="vegano">Vegan Menu</span> 
            </div>
            } else{
                return <div className="container col-md-12 menuSelector">
                <span className="special" onClick={()=>this.switchMenu(0)} id="menu" href="menu">Menu original</span>&nbsp;|&nbsp;
                <span className="special" onClick={()=>this.switchMenu(1)} id="gluten" href="gluten">Menu sin gluten</span>&nbsp;|&nbsp;
                <span className="special" onClick={()=>this.switchMenu(2)} id="vegano" href="vegano">Menu vegano</span> 
            </div>
            }
        }

        getContactUs() {
            if (this.props.language==="en") {
                return <div className="row container"> 
                    <div className="col-md-6 columnSetting">   
                        <h3 className="header1" id="contacto">{this.state.pageSections ? this.state.pageSections.data[0].acf.title_english : ""}</h3> 
                        <span className="info">{this.state.pageSections ? this.state.pageSections.data[0].acf.description_english : ""}</span>
                        <div className="picture3">
                           <MapSection location={location} zoomLevel={17} />
                        </div>                                                 
                    </div>                    

                   <div className="col-md-6 columnSetting">
                        <h3 className="header1">Reserve your table!</h3>   
                        <ReservasEn/>                   
                    </div>
                </div>
            } else {
                return <div className="row container"> 
                    <div className="col-md-6 columnSetting">   
                        <h3 className="header1" id="contacto">{this.state.pageSections ? this.state.pageSections.data[0].acf.title_espanol : ""}</h3> 
                        <span className="info">{this.state.pageSections ? this.state.pageSections.data[0].acf.description_espanol : ""}</span>
                        <div className="picture3">
                           <MapSection location={location} zoomLevel={17} />
                        </div>                                                 
                    </div>                    

                   <div className="col-md-6 columnSetting">
                        <h3 className="header1">Reserva tu mesa!</h3>   
                        <ReservasEs/>                   
                    </div>
                </div>
            }
        }

        async sendOrder () {  
            
            const orderPhone = document.getElementById('orderPhone').value;
            if (orderPhone==="") {
                if (this.props.language==="en") {
                    alert("Please provide your phone number");
                } else {
                    alert("Tienes que darnos tu numero de teléfono");
                }
              return false;
            }
            else {
              
              const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                  phone: orderPhone,
                  cartData: this.props.cartData,
                  cartDataRaw: this.state.productsRaw
                })};
        
              await fetch('https://lacasitamexicana.akiredo.com/storeOrder.php', requestOptions)
                .then(response => response.json())
                .then(data => {})
        
              await this.props.flushCart();

                if (this.props.language==="en") {
                    toast('Order sent! Thank you!'); 
                } else {
                    toast('Pedido enviado! Muchas gracias!'); 
                }                
            
                this.populateCart(this.state.menuContentsJson);
               
            }
          }

    render() {

        let menuContentEn = this.state.menuContentsEn && this.props.language==="en" ? this.state.menuContentsEn : "  Loading menu...";
        let menuContentEs = this.state.menuContentsEs && this.props.language!=="en" ? this.state.menuContentsEs : "  Cargando menu...";
        let menuGlutenEn = this.state.menuContentsGlutenFreeEn && this.props.language==="en" ? this.state.menuContentsGlutenFreeEn : "  Loading menu...";
        let menuGlutenEs = this.state.menuContentsGlutenFreeEs && this.props.language!=="en" ? this.state.menuContentsGlutenFreeEs : "  Cargando menu...";
        let menuVeganEn = this.state.menuContentsVeganEn && this.props.language==="en" ? this.state.menuContentsVeganEn : "  Loading menu...";
        let menuVeganEs = this.state.menuContentsVeganEs && this.props.language!=="en" ? this.state.menuContentsVeganEs : "  Cargando menu...";
        let galleryItemListing = this.state.galleryItems ? this.state.galleryContents : "  Cargando galeria...";

        let menuActive = "";
        if (this.state.menuActive===0) {
            menuActive = this.props.language==="en" ? menuContentEn : menuContentEs;
        }
        if (this.state.menuActive===1) {
            menuActive = this.props.language==="en" ? menuGlutenEn : menuGlutenEs;
        }
        if (this.state.menuActive===2) {
            menuActive = this.props.language==="en" ? menuVeganEn : menuVeganEs;
        }

        let menuChoice = this.getMenuChoice();
        let contactUs = this.getContactUs();
        let aboutUs = this.getAboutUs();

        return(
            <div>

                <div className="picture"></div>

                <div className="row">
                    {aboutUs}                     
                </div>
                <br />
                <br />
                <div id="divCart" className={this.state.carritoClass}>
                    <img alt={this.props.language==="en" ? "Order to Pick up" : "Pedido para recoger"} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABD0lEQVRIie3WvUrDUBiA4Qdx0VVBHQXvwMnJUYvg7EV4CV6N16CDdaodHARHV6V1FAWHilKtQxKs2DYn6Uk61BfOkvNx3u8n4YR5pY1Buh5wNAvxAH1s1SXPOE3lJ3WLD1Pxdd3iZfTwibWqJAsjnvXQSvf2qhKP49jvly3WusoTb1YkfswEo1oN97jLy64El3liOK9SPIldcdv8hfUQ8SJeIopvhw+f1Oo+LkIyDKQZKibunIPmm7EqqXzaNvewNHxwXsVPuCmS6RhaeCsihrMI4mZ+yF828Kp8m5+xUjbjHck1+VFA+C75wdguK51fDiS3SxeNCHHBdP3MrzNtXMjnNHMakmo62I8Q9089fAPfl7KJKlqUDwAAAABJRU5ErkJggg=="/>
                    &nbsp;
                    <strong>{this.props.language==="en" ? "Order to Pick up" : "Pedido para recoger" }</strong><br/><br/>
                    <div className="divCartBox">
                        <div className="row"> 
                            <div className="col-6 cartHeader">{this.props.language==="en" ? "Product" : "Producto" }</div>
                            {/* <div className="col-2 cartHeader">Precio</div> */}
                            <div className="col-3 cartHeader">{this.props.language==="en" ? "Amount" : "Cantidad" }</div>
                            <div className="col-3 cartHeader right">Total</div> 
                        </div>
                        {this.state.products}
                        <div className="row"> 
                            <div className="col-6 cartHeader">&nbsp;</div>
                            {/* <div className="col-2 cartHeader">&nbsp;</div> */}
                            <div className="col-3 cartHeader">&nbsp;</div>
                            <div className="col-3 cartHeader right">{Number(this.state.totalPrice).toFixed(2)}€</div> 
                        </div>
                        <p></p> 

                        {/* <a href="https://api.whatsapp.com/send?phone=0034662430031&text=Hello%2C%0D%0A%0D%0AMy+Order+is%3B%0D%0A%0D%0AVindalloo+for+something%0D%0ASomething%0D%0A%0D%0A" target="_blank">  Click to WhatsApp Chat</a> */}
                        
                        <input type="text" id="orderPhone" className="Input" placeholder={this.props.language==="en" ? "Your Phone Number" : "Tu numero de teléfono" }></input> <Button className="btnCheckout" onClick={()=>this.sendOrder()}>{this.props.language==="en" ? "Send Order!" : "Enviar pedido!" }</Button><br/>
                        <span>{this.props.language==="en" ? "Payment is made at restaurant upon pickup. We will call to confirm your order" : "El pago se hace en el restaurante. Te llamamos para confirmar tu pedido." }</span>
                    </div>
                </div>

                {menuChoice}

                {menuActive}                  

                <div className="picture1"></div>

                <div className="row container">
                    <div className="col-12">
                        <h3 className="header1 lessTopMargin">{this.props.language==="en" ? "Gallery" : "Galeria"}</h3> 
                        {galleryItemListing}
                    </div>                
                </div>

                {contactUs}

                {/* Footer */}
                <div className="row">
                    <div className="infoFooter">
                        <a className="col-md-4" href="https://www.facebook.com/"><img className="FB" alt="Facebook" src={fb_NY}/></a>
                        <a className="col-md-4" href="https://www.tripadvisor.se/"><img className="trip" alt="Tripadviser" src={tripadvisor_white}/></a>
                    </div>
                </div>                                
            </div>  
                           
        )
    }
}

export default Home;