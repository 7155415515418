import http from "../base/httpcomponent";

class MenuCategoryDataService {
    getAll() {
        return http.get("/menucategory?per_page=100");
    }

    getAllByMainMenuCategoryId(id) {
        return http.get(`/menucategory?mainmenucategory=${id}&per_page=100`);
    }

    get(id) {
        return http.get(`/menucategory/${id}`);
    }
}

export default new MenuCategoryDataService();