import http from "../base/httpcomponent";

class GalleryItemDataService {
    getAll() {
        return http.get("/galleryitem?per_page=100");
    }

    get(id) {
        return http.get(`/galleryitem/${id}`);
    }
}

export default new GalleryItemDataService();