import './App.css';
import React from 'react';
import NavBar from "../src/components/navBar/navBar";
import Home from "../src/components/home/home";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './i18n';
import toast, { Toaster } from 'react-hot-toast';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class App extends React.Component { 
  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  constructor(props) {
    super(props);

    this.addCartItem = this.addCartItem.bind(this);
    this.removeCartItem = this.removeCartItem.bind(this);
    this.emptyCart = this.emptyCart.bind(this);
    this.flushCart = this.flushCart.bind(this);
    this.switchLanguage = this.switchLanguage.bind(this);

    this.state = {
        cookieJar: this.props.cookies.get("casitamexicana") || [],
        cartTotalAmount: 0,
        language: ""
      };
  };  

  componentDidMount() {
    if (this.state.cookieJar === 'undefined' || !Array.isArray(this.state.cookieJar)) {
      this.setDefaultCookie();
    }

    this.updateCartTotal();
  }

  async flushCart() {
    const { cookies } = this.props; 
    await cookies.set('casitamexicana', [], { path: '/' });
    this.setState({cookieJar: cookies.get("casitamexicana")});
  }

  setDefaultCookie() {
    this.emptyCart();
  }

  emptyCart() {
    const { cookies } = this.props; 
    cookies.set('casitamexicana', [], { path: '/' });
    this.setState({cookieJar: cookies.get("casitamexicana")});
  }

  updateCookie() {
    const { cookies } = this.props;
    cookies.set("casitamexicana", this.state.cookieJar.filter((item) => Number(item.amount) > 0), { path: "/" });    
    this.updateCartTotal();
    this.setState({cookieJar: cookies.get("casitamexicana")});
  }

  updateCartTotal() {
    let productCount = 0;
    
    for (var i=0; i<this.state.cookieJar.length; i++) {
      if (Number(this.state.cookieJar[i].amount)>0) {
        productCount = productCount + this.state.cookieJar[i].amount;
      }
    }

    if (productCount>0) {
      this.setState({cartTotalAmount: productCount});    
    }
  }

  addCartItem (productId, productSize, amount) {
     
    let tmpArr = this.state.cookieJar;
    if (productId==='undefined' || !productId) {
      return false;
    }

    let match=false;
    for (var i=0; i<tmpArr.length; i++) {
      if (parseInt(tmpArr[i].id) === parseInt(productId) && parseInt(tmpArr[i].size) === parseInt(productSize)) {
        tmpArr[i].amount=Number(tmpArr[i].amount)+Number(amount);
        tmpArr[i].size=productSize;
        match=true;
      }
    }

    if (match === false) {
      tmpArr.push({id:productId,amount:amount,size:productSize})
    }

    tmpArr = tmpArr.filter(item => Number(item.amount)>0);
    
    this.setState({cookieJar: tmpArr});
    this.updateCookie();
  }

removeCartItem (productId, sizeValue) {
  // Initiera cookies
  const { cookies } = this.props;
  let tmpArr = this.state.cookieJar;
    if (productId==='undefined' || !productId) {
      return false;
    }
    // Loopa igenom cartNow och hitta matchande produkter
    for (var i=0; i<tmpArr.length; i++) {
      if (parseInt(tmpArr[i].id) === parseInt(productId) && parseInt(tmpArr[i].size) === parseInt(sizeValue)) {
        tmpArr[i].amount--;
      }
    }

    tmpArr = tmpArr.filter(item => Number(item.amount)>0);

    cookies.set("casitamexicana", tmpArr, { path: "/" });
    this.setState({cookieJar: tmpArr});    
    this.updateCartTotal();
    this.updateCookie();
}
    
  async switchLanguage(inValue) {        
      await this.setState({language: inValue});
      console.log(this.state.language)
      if (this.state.language==="en") 
      {
        toast('Switched to English!'); 
      } else 
      {
        toast('Cambiado a Español!');
      }
  }
  
  render() {
    
    return (
      <div className="App container">
        <Toaster
  position="top-center"
  reverseOrder={false}
/>
          <header className="App-header">
              <NavBar switchLanguage={this.switchLanguage} language={this.state.language} />                
          </header>
          <div className="App-content">
            <Router>        
              <Switch>
                  <Route path="/" render={(props) => <Home flushCart={this.flushCart} cartTotalAmount={this.state.cartTotalAmount} cookieJar={this.state.cookieJar} emptyCart={this.emptyCart} addCartItem={this.addCartItem} removeCartItem={this.removeCartItem} cartData={this.state.cookieJar}  language={this.state.language} {...props} /> } />  
              </Switch>      
            </Router>             
          </div>
      </div>              
    );
  }
}

export default withCookies(App);