import http from "../base/httpcomponent";

class MediaDataService {
    getAll() {
        return http.get("/media?per_page=100");
    }

    get(id) {
        return http.get(`/media/${id}`);
    }
}

export default new MediaDataService();