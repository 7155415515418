import axios from "axios";

const API_URL = "https://wpapi3.lerudi.com/wp-json/wp/v2/";

export default axios.create({

    baseURL: API_URL,
    headers: {
        "Content-type": "application/json;",
    }
});