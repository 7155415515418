import http from "../base/httpcomponent";

class MainMenuCategoryDataService {
    getAll() {
        return http.get("/mainmenucategory?filter[orderby]=order&order=asc");
    }

    get(id) {
        return http.get(`/mainmenucategory/${id}`);
    }
}

export default new MainMenuCategoryDataService();