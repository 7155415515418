import http from "../base/httpcomponent";

class PageSectionDataService {
    getAll() {
        return http.get("/pagesection/");
    }

    get(id) {
        return http.get(`/pagesection/${id}`);
    }
}

export default new PageSectionDataService();