import React from "react";
import "./reservas.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import toast from 'react-hot-toast';

export class ReservasEn extends React.Component {
    static displayName = "";

    constructor(props) {
        super(props);
        
        this.changeDate = this.changeDate.bind(this);
        this.submitReservation = this.submitReservation.bind(this);

        this.state = {            
            minOrderDate: new Date()
        }
    }

    async componentDidMount() {
        var result = new Date();
        result.setDate(result.getDate() + 1);
        var someResult = new Date();
        someResult.setDate(someResult.getDate() + 1);

        this.setState({minOrderDate: someResult, value: result});
    }

    changeDate(inDate) {
        this.setState({value: inDate}) 
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
  
    validateContents() {
      const fullName = document.getElementById('fullName').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
  
      let errorSummary = "";
      
      if (!fullName || !fullName.length > 0)
      {
        errorSummary = errorSummary + "Falta Nombre\n";
      }
      if (!email || !email.length > 0 || this.validateEmail(email) === false)
      {
        errorSummary = errorSummary + "Falta E-mail valido\n";
      }
      if (!phone || !phone.length > 0)
      {
        errorSummary = errorSummary + "Falta Numero de Teléfono\n";
      }
  
      return errorSummary;
    }
  
    async createReservation () {   
  
      const validationText = this.validateContents();
      if (validationText!=="") {
        alert("Habia errores con vuestra reserva;\n\n" + validationText);
        return false;
      }
      else {
            let fecha = new Date(this.state.value).toLocaleDateString()
            const time = document.getElementById('time').value;
            const fullName = document.getElementById('fullName').value;
            const email = document.getElementById('email').value;
            const group = document.getElementById('group').value;
            const phone = document.getElementById('phone').value;
            const message = document.getElementById('message').value;
            
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                fecha: fecha,
                time: time,
                fullName: fullName,
                email: email,
                phone: phone,
                group: group,
                message: message,
            })};      

        await fetch('https://lacasitamexicana.akiredo.com/autoMailer.php', requestOptions)
          .then(response => response.json())
          .then(data => {console.log(data)})
          .catch(err => console.error(err));

          toast('Reserva enviada!');
        }
    }

    submitReservation () {
        let something = window.confirm("Seguro que tienes todo rellenado correctamente?");
        if (something) {
            console.log("Reservado!");
            this.createReservation();
        }
    }

    render() {

        let datePicker = this.state.minOrderDate ? <DatePicker id="fecha" 
                                                    className="form-control" 
                                                    selected={this.state.value}
                                                    minDate={this.state.minOrderDate} 
                                                    onChange={(value) => this.changeDate(value)} /> : "";

        return (
            <div>
                {/* <span className="header1">Reserva tu plaza con nosotros</span> */}
                
                <Form className="checkoutForm info" id="reservas">
                <FormGroup row>
                        <Label for="group" sm={2}>Date*</Label>
                        <Col sm={4}>
                            {datePicker}
                        </Col>   
                        <Label for="time" sm={2}>Hour*</Label>
                        <Col sm={4}>
                        <select name="time" type="time" className="form-control" id="time">
                            <option value="13.00">13.00</option>
                            <option value="13.30">13.30</option>
                            <option value="14.00">14.00</option>
                            <option value="14.30">14.30</option>
                            <option value="15.00">15.00</option>
                            <option value="15.30">15.30</option>
                            <option value="16.00">16.00</option>
                            <option value="16.30">16.30</option>
                            <option value="17.00">17.00</option>
                            <option value="17.30">17.30</option>
                            <option value="18.00">18.00</option>
                            <option value="18.30">18.30</option>
                            <option value="19.00">19.00</option>
                            <option value="19.30">19.30</option>
                            <option value="20.00">20.00</option>
                            <option value="20.30">20.30</option>
                            <option value="21.00">21.00</option>
                            <option value="21.30">21.30</option>
                            <option value="22.00">22.00</option>
                            <option value="22.30">22.30</option>
                            <option value="23.00">23.00</option>
                            <option value="23.30">23.30</option>
                            <option value="24.00">24.00</option>
                        </select>
                        </Col>            
                    </FormGroup>
                    <FormGroup row> 
                        <Label for="group" sm={2}>Group*</Label>
                        <Col sm={4}>
                            <select name="group" className="form-control" id="group">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </Col>       
                        <Label for="fullName" sm={2}>Name*</Label>
                        <Col sm={4}>
                            <Input type="fullName" name="fullName" id="fullName" placeholder="Maria Gonzales" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email" sm={2}>E-mail*</Label>
                        <Col sm={4}>
                            <Input type="email" name="email" id="email" placeholder="maria@email.com" />
                        </Col>
                        <Label for="phone" sm={2}>Phone</Label>
                        <Col sm={4}>
                            <Input type="phone" name="phone" id="phone" placeholder="616123456" />
                        </Col>
                    </FormGroup>
                <FormGroup row>
                    <Label for="exampleText" sm={2}>Message</Label>
                    <Col sm={10}>
                        <Input type="textarea" name="message" id="message" placeholder="" />
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col sm={12}>
                        <div className="row">
                            <div className="col-md-8">&nbsp;</div>

                            <div className="col-md-4">
                                <input type="button" onClick={()=>this.submitReservation()} className="btn btnspecial" value="Send"></input>
                            </div>
                        </div>
                    </Col>
                </FormGroup>
                </Form>
            </div>
        )
    }
}

export default ReservasEn;