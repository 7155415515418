import React from "react";
import "./navBar.css";
import logo_NY from "../pictures/logo_NY.png";
import spanish_flag from "../pictures/spanish_flag.png";
import english_flag from "../pictures/english_flag.png";
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';

export class NavBar extends React.Component {
    constructor(props) {
                super(props);
            
            this.toggleNavbar = this.toggleNavbar.bind(this);
            this.state = {
                isOpen: false,
                collapsed: true,
                chosenLingo: "en",
            };
          };
        
        toggleNavbar () {
            this.setState({
                isOpen: !this.state.isOpen
            });
        };    
        
    render() {

        let showFlag = this.props.language === "en" ? <img className="flag_spanish" alt="Cambiar a español" title="Cambiar a español" onClick={()=>this.props.switchLanguage("es")} src={spanish_flag}></img> : <img className="flag_english" alt="Switch to English" title="Switch to English" onClick={()=>this.props.switchLanguage("en")} src={english_flag}></img>;
        let menuContents = this.props.language === "en" ? <Nav navbar>
        <NavItem>
            <NavLink href="#contacto">Contact Us</NavLink>
        </NavItem>
        <NavItem>
            <NavLink href="#reservas">Reservations</NavLink>
        </NavItem>
        <NavItem>
            <NavLink href="#menu">Menu</NavLink>
        </NavItem> </Nav> : <Nav navbar>
        <NavItem>
            <NavLink href="#contacto">Contacto</NavLink>
        </NavItem>
        <NavItem>
            <NavLink href="#reservas">Reservas</NavLink>
        </NavItem>
        <NavItem>
            <NavLink href="#menu">Menu</NavLink>
        </NavItem> </Nav>;             

        return (
            <div>
                <img className="logo desktopVisible" alt="" src={logo_NY}/>
                <div className="navbar-wrapper">                    
                    <Navbar color="white" light expand="sm">
                    <NavbarBrand className="nonVisible" href="/"></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                        
                            {menuContents}                                        
                        
                        </Collapse>
                        <div>
                            {showFlag}
                        </div>
                        
                    </Navbar>
                    
                </div> 
            </div>                           


        )

        }
    }
export default NavBar;